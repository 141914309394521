import Grid from "@material-ui/core/Grid";
import React from "react";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";


class ServiceInfo extends React.Component {
    render() {
        return (
            <Grid container direction={"column"} alignItems={"flex-start"} justify={"center"}>
                <Grid item className={'sectionBodyStyle'}>
                    <i className='ico-blue icon-time'/><i
                    className={'date_cal'}>&nbsp;Horaires</i>
                </Grid>
                <Grid container direction={'row'} justify={'flex-start'}>
                    <Select
                        value={this.props.input_heure}
                        onChange={this.props.comboBoxHandleChange}
                        variant={(this.props.service.horaires.find((horaire) => horaire === this.props.input_heure)) && 'filled' || 'outlined'}
                        style={
                            (this.props.service.horaires.find((horaire) => horaire === this.props.input_heure)) &&
                            {
                                color: 'white',
                                backgroundColor: 'rgb(0,159,140)'
                            }
                            ||
                            {
                                color: 'rgb(0,44,66)'
                            }
                        }
                    >
                        {this.props.service.horaires.map((horaire, i) => {
                            return (<MenuItem id={'hr' + horaire.heure} m={20}
                                              key={horaire.heure}
                                              value={horaire.heure}
                                              className={`${horaire === this.props.input_heure && 'active-hr-item'}`}
                                              disabled={horaire.fermee === 1}
                            >{horaire.heure}</MenuItem>)
                        })}
                    </Select>
                </Grid>
            </Grid>
        );
    }
}

export default ServiceInfo;
