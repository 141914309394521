import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import React from "react"; // don't delete it
import i18n from "../utils/i18n";

export default props => (<Grid item>
    <Grid container direction={'row'} alignItems={'flex-start'}>
        <Grid item>
            <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}
                className='labelMarginBottom'>
                <i className={'icon-civilité'} />
                <label>{i18n.t('Civilité')}</label>
            </Grid>
            <Select autoWidth={true}
                value={props.userForm.contact.civilite_id}
                onChange={props.handleFormChange('civilite_id')}
                onBlur={props.onExitInputField('civilite_id')}
                MenuProps={{
                    style: { right: '10px', left: '0px' },
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'top'
                    }
                }}
            >
                <MenuItem value="1">{i18n.t('Homme')}</MenuItem>
                <MenuItem value="2">{i18n.t('Femme')}</MenuItem>
                <MenuItem value="3">{i18n.t('Autre')}</MenuItem>
            </Select>
        </Grid>
        <Grid item xs>
            <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}
                className='labelMarginBottom'>
                <i className={`icon-user ${(props.userForm.error.nom || props.userForm.error.prenom) && 'colorError'}`} />
                <label
                    className={`${(props.userForm.error.nom || props.userForm.error.prenom) && 'mentions_error'}`}>{i18n.t('Nom/Prénom')}</label>
            </Grid>
            <TextField
                InputProps={{
                    disableUnderline: true,
                    style: { marginRight: 0 },
                    className: `${props.userForm.error.nom && 'border_colorError'}`
                }}
                style={{ width: '100%' }}
                id='signIn-nom-text'
                value={props.userForm.contact.nom}
                onChange={props.handleFormChange('nom')}
                onBlur={props.onExitInputField('nom')}
                placeholder={i18n.t('Nom')}
                type={'text'}
            />
            <TextField
                InputProps={{
                    disableUnderline: true,
                    style: { marginRight: 0, marginBottom: '10px' },
                    className: `${props.userForm.error.prenom && 'border_colorError'}`
                }}
                style={{ width: '100%' }}
                id='signIn-prenom-text'
                className={'lastDivElem'}
                value={props.userForm.contact.prenom}
                onChange={props.handleFormChange('prenom')}
                onBlur={props.onExitInputField('prenom')}
                placeholder={i18n.t('Prénom')}
                type={'text'}
            />
        </Grid>
    </Grid>
    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} className='labelMarginBottom'>
        <i className={`icon-mail ${(props.userForm.error.confirm_email || props.userForm.error.email) && 'colorError'}`} />
        <label
            className={`${(props.userForm.error.confirm_email || props.userForm.error.email) && 'mentions_error'}`}>{i18n.t('Adresse email')}</label>
    </Grid>
    <Grid container direction={'column'}>
        <TextField
            InputProps={{
                disableUnderline: true,
                style: { marginRight: 0 },
                className: `user_mail_input ${props.userForm.error.confirm_email && 'border_colorError'}`
            }}
            id='email-text'
            value={props.userForm.contact.email}
            onChange={props.handleFormChange('email')}
            placeholder={i18n.t('votremail@gmail.com')}
            type={'text'}
        />
        <TextField
            InputProps={{
                disableUnderline: true,
                style: { marginRight: 0, marginBottom: '10px' },
                className: `${props.userForm.error.confirm_email && 'border_colorError'}`
            }}
            id='conf-email-text'
            value={props.userForm.contact.confirm_email}
            onChange={props.handleFormChange('confirm_email')}
            onBlur={props.checkEquality()}
            placeholder={i18n.t('Confirmez votre adresse e-mail')}
            type={'text'}
        />
        <Collapse in={props.userForm.error.confirm_email || props.userForm.error.email}>
            <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
                <Grid item>
                    <div className={'error_input'}>{i18n.t('Les adresses doivent être identiques.')}</div>
                </Grid>
            </Grid>
        </Collapse>
    </Grid>
    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} className='labelMarginBottom'>
        <i className={`icon-portable ${props.userForm.error.portable && 'colorError'}`} />
        <label className={`${props.userForm.error.portable && 'mentions_error'}`}>{i18n.t('Portable')}</label>
    </Grid>
    <Grid container direction={'row'} alignItems={'center'} alignContent={"center"}>
        <Grid item>
            <TextField
                InputProps={{
                    disableUnderline: true,
                    style: { marginBottom: '10px' },
                    className: `${props.userForm.error.portable && 'border_colorError'}`
                }}
                style={{ width: '100%' }}
                id='portable-text'
                value={props.userForm.contact.portable}
                onChange={props.handlePortableChange}
                onBlur={props.onExitInputField('portable')}
                placeholder={'06 12 34 56 78'}
                type={'text'}
            />
        </Grid>
        {props.verificationPortable && <><Grid item>
            <Button disabled={!props.userForm_enableVerif}
                size={'small'}
                color={props.userForm_enableVerif === 'cooldown' && "secondary" || "primary"}
                variant={props.userForm_enableVerif === 'cooldown' && 'contained' || 'outlined'}
                onClick={props.onClickVerifPhone}>{i18n.t('Vérifier')}</Button>
        </Grid>
            <Collapse in={props.userForm_enableVerif === 'cooldown'}>
                <Grid item>
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Grid>
            </Collapse></>}
    </Grid>
    <Collapse in={props.userForm.error.portable}>
        <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
            <Grid item>
                <div
                    className={'error_input_tel'}>{i18n.t('Le numéro de téléphone doit être composé d\'au moins 10 chiffres.')}</div>
            </Grid>
        </Grid>
    </Collapse>
    {props.verificationPortable && <Collapse in={props.code_drop_down}>
        <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}
            className='labelMarginBottom'>
            <i className={`icon-receive_message ${props.userForm.error.phone_code && 'colorError'}`} />
            <label
                className={`${props.userForm.error.phone_code && 'mentions_error'}`}>{i18n.t('Code reçu par SMS')}</label>
        </Grid>
        <Grid container direction={'row'} alignItems={'stretch'}>
            <Grid item xs={4}>
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        style: { marginBottom: '10px' },
                        className: `${props.userForm.error.phone_code && 'border_colorError'}`
                    }}
                    style={{ width: '100%' }}
                    id='portable-text'
                    value={props.userForm.phone_code}
                    onChange={props.handlePortableCodeChange}
                    placeholder={'12345'}
                    type={'text'}
                />
            </Grid>
            <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                <i className={`${props.userForm.phone_code_icon}`} />
            </Grid>
        </Grid>
    </Collapse>}
    {!props.asGuest && <Grid container direction={'row'} justify={"flex-start"} alignItems={'center'}>
        <Grid item>
            <Checkbox
                style={{ marginLeft: '-2.5px' }}
                checked={props.userForm.contact.sauvegarder === true}
                onChange={props.handleFormChange('sauvegarder', 'box')}
                color={props.userForm.contact.sauvegarder && 'secondary' || 'primary'}
                variant={props.userForm.contact.sauvegarder && 'contained' || 'outlined'}
                value={props.userForm.contact.sauvegarder}
            />
        </Grid>
        <Grid item xs className={'mention'}>
            <span onClick={props.handleFormChange('sauvegarder', 'box')}>{i18n.t('M\'enregistrer pour de futures réservations')}</span>
        </Grid>
    </Grid>}
    <Grid container direction={'column'} alignItems={'stretch'} justify={'flex-start'}>
        <Grid container direction={'row'} justify={"flex-start"} alignItems={'center'}>
            <Grid item>
                <Checkbox
                    style={{ marginLeft: '-2.5px' }}
                    checked={props.userForm.contact.exclus_newsletter === true}
                    onChange={props.handleFormChange('exclus_newsletter', 'box')}
                    value={props.userForm.contact.exclus_newsletter}
                    color={props.userForm.contact.exclus_newsletter && 'secondary' || 'primary'}
                    variant={props.userForm.contact.exclus_newsletter && 'contained' || 'outlined'}

                />
            </Grid>
            <Grid item xs className={'mentions'}>
                <p onClick={props.handleFormChange('exclus_newsletter', 'box')} style={{ textAlign: "left" }}>{i18n.t('En cochant cette case, j\'accepte de recevoir des informations commerciales de l\'établissement. Sinon vous ne recevrez que les informations propre à cette réservation')}</p>
            </Grid>
        </Grid>
        <Grid container direction={'row'} justify={"center"} alignItems={'center'}>
            <Grid item>
                <Checkbox
                    style={{ marginLeft: '-2.5px' }}
                    checked={props.userForm.contact.mentionsIsAccepted === true}
                    onChange={props.handleFormChange('mentionsIsAccepted', 'box')}
                    value={props.userForm.contact.mentionsIsAccepted}
                    className={`checkbox_mention ${props.userForm.error.mentionsIsAccepted && 'colorError'}`}
                    color={props.userForm.contact.mentionsIsAccepted && 'secondary' || 'primary'}
                    variant={props.userForm.contact.mentionsIsAccepted && 'contained' || 'outlined'}
                />
            </Grid>
            <Grid item xs className={'mentions'}>
                <p style={{ textAlign: "left" }}
                    onClick={props.handleFormChange('mentionsIsAccepted', 'box')}
                    className={`mentions_leg ${props.userForm.error.mentionsIsAccepted && 'mentions_error'}`}>{i18n.t(`J'accepte les`)} <a href={'https://euresto.com/mentions-legales-reservation/'} target={'_blank'}>{i18n.t(`conditions générales d'utilisation et la Politique de confidentialité d'Euresto`)}</a></p>
            </Grid>
        </Grid>
    </Grid>
    <Grid container direction={'row'} alignItems={'center'} justify={'flex-end'}>
        <Grid item xs={4}>
            <Button
                size={'small'}
                color={props.userForm.contact.mentionsIsAccepted && "secondary" || "primary"}
                variant={props.userForm.contact.mentionsIsAccepted && 'contained' || 'outlined'}
                onClick={props.nextOnclick}
                fullWidth>{i18n.t('Finaliser')}</Button>
        </Grid>
    </Grid>
</Grid>)
