import Grid from "@material-ui/core/Grid";
import TypeRepas from "./TypeRepas";
import React from "react";

class LayoutDefault extends React.Component {
    render() {
        return (
            <Grid item className={'sectionStyle'}>
                <Grid container direction={'column'}>
                    <TypeRepas input_heure={this.props.input_heure} input_service_id={this.props.input_service_id} typeHandleChange={this.props.typeHandleChange}
                        comboBoxHandleChange={this.props.comboBoxHandleChange}
                        servicesJourProduit={(this.props.jour || []).filter(el => el.produit_id === this.props.input_produit_id)}
                        comboHoraire={this.props.comboHoraire} />
                </Grid>
            </Grid>
        );
    }
}
export default LayoutDefault;