import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector/index'
import Backend from 'i18next-xhr-backend/index';

export default i18next
    .use(LanguageDetector)
    .use(Backend)
    .init({
        lng: "fr",
        fallbackLng: 'fr',
        ns: ['commun'],
        whitelist: ['fr', 'en'],
        defaultNS: 'commun',
        debug: false,
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
    }, (err, t) => {})