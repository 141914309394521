import React, { Component } from "react";
import Grid from '@material-ui/core/Grid'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import i18n from "../utils/i18n";

class Menu extends Component {
    renderCarteService = (service) => {
        return (<Grid container direction={'row'} justify={'center'} alignItems={'center'} key={service.service_id}>
            <Grid container direction={'row'} alignItems={'center'} justify={'center'} item xs={12}>
                <FormControlLabel disabled={service.fermeture > 0 || service.complet > 0}
                    control={<Radio color="default" disabled={service.fermeture > 0 || service.complet > 0}
                        checked={this.props.input_service_id === service.service_id} />}
                    label={service.libelle} value={service.service_id} />
            </Grid>
            <div className={'dangerously_styled'} style={{ textAlign: 'center', maxWidth: '100%' }}
                dangerouslySetInnerHTML={{ __html: service.menu }} />
        </Grid>);
    };

    renderPageNumber = () => {
        const nbProduits = this.numberProduits()
        return nbProduits > 1 && <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-end'}
            className={'padding_right pageNumbers'}>
            <p>{this.numberCurrentProduit()}</p>
            <p>/{nbProduits}</p>
        </Grid>;
    };

    numberProduits = () => {
        const prod_serv = this.props.jour.filter(service => service.menu !== null && service.menu !== '' && service.menu !== undefined);
        const prod_serv_ids = prod_serv.map(service => service.produit_id);
        const unique_prod = prod_serv_ids.filter((element, i) => prod_serv_ids.indexOf(element) >= i);
        return unique_prod.length;
    };

    numberCurrentProduit = () => {
        const prod_serv = this.props.jour.filter(service => service.menu !== null && service.menu !== '' && service.menu !== undefined);
        const prod_serv_ids = prod_serv.map(service => service.produit_id);
        const unique_prod = prod_serv_ids.filter((element, i) => prod_serv_ids.indexOf(element) >= i);
        return unique_prod.indexOf(this.props.produitMenu.id) + 1;
    };

    allProduitMenusareEmpty = () => {
        const tab = (this.props.jour.filter(service => service.produit_id === this.props.produitMenu.id)).map(serviceProduit => {
            if (serviceProduit.menu === '' || serviceProduit.menu === null || serviceProduit.menu === undefined) return null;
            else return serviceProduit
        });
        return tab.every(el => el === null);
    };


    render() {
        const nbProduits = this.numberProduits()
        return (
            <Grid container direction={'column'} alignItems={'center'} className={'no_margin_left'}>
                <Grid container direction={"row"} alignItems={"stretch"}>
                    {nbProduits > 1 && <Grid item xs={2}>
                        <Grid container direction={'row'} alignItems={'center'} justify={'flex-start'}
                            className={'no_margin_left'}>
                            <i id='prev' onClick={this.props.menuArrowHandleClick} className={'fa fa-caret-left'}
                                style={{
                                    color: 'white',
                                    backgroundColor: 'rgb(0,44,66)',
                                    borderRadius: 5,
                                    padding: '5px 15px',
                                    cursor: 'pointer',
                                    fontSize: 20,
                                }} />
                        </Grid>
                    </Grid>}
                    <Grid item xs={nbProduits > 1 && 8 || 12}>
                        <Grid container style={{ height: '100%' }} direction={"row"} alignItems={"center"}
                            justify={'center'}>
                            <Grid item>
                                <h1 className={'menu_carte'}
                                    style={{ textAlign: 'center', fontSize: '25px' }}>{i18n.t('Menu')}</h1>
                            </Grid>
                        </Grid>
                    </Grid>
                    {nbProduits > 1 && <Grid item xs={2}>
                        <Grid container direction={"row"} alignItems={"center"} justify={"flex-end"}>
                            <i id='next' onClick={this.props.menuArrowHandleClick} className={'fa fa-caret-right'}
                                style={{
                                    color: 'white',
                                    backgroundColor: 'rgb(0,44,66)',
                                    borderRadius: 5,
                                    padding: '5px 15px',
                                    cursor: 'pointer',
                                    fontSize: 20,
                                }} />
                        </Grid>
                    </Grid>}
                </Grid>
                {
                    this.allProduitMenusareEmpty() === false ?
                        <Grid item>
                            <h2>{this.props.produitMenu.libelle}</h2>
                            <RadioGroup id="menuList" value={this.props.produitMenu.id.toString()}
                                onChange={this.props.menuListHandleChange}>
                                <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
                                    {(this.props.jour.filter(service => service.produit_id === this.props.produitMenu.id)).map(serviceProduit => {
                                        if (serviceProduit.menu !== '' && serviceProduit.menu !== null && serviceProduit.menu !== undefined) {
                                            return this.renderCarteService(serviceProduit)
                                        }
                                    })}
                                </Grid>
                            </RadioGroup>
                        </Grid>
                        : null}
                {this.renderPageNumber()}
            </Grid>
        );
    }
}

export default Menu;
