import Grid from "@material-ui/core/Grid";
import React, {Component} from "react";
import Button from "@material-ui/core/Button";


class ConfirmationResa extends Component {

    render() {
        return (
            <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                <div dangerouslySetInnerHTML={{__html: this.props.message_fin_resa}}/>
                <Button onClick={this.props.closeResa} style={{
                    backgroundColor: 'rgb(0,44,66)',
                    color: 'white',
                    marginTop: '20',
                    padding: 5
                }}>Accueil</Button>
            </Grid>
        )
    }
}

export default ConfirmationResa;