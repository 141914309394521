import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import i18n from "../utils/i18n";


/*Input text partie complémentaire*/
export default props => <Grid container direction={'column'} alignItems={"stretch"}>
    <TextField
        InputProps={{
            disableUnderline: true,
            className: 'info_input sectionBodyStyle',
        }}

        multiline
        className='infoinput'
        placeholder={i18n.t('Allergies, Personnes à mobilité réduite')}
        rows={5}
        rowsMax={10}
        value={props.input_commentaire}
        onChange={props.infoComplementairesHandleChange}
    />
</Grid>
