import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React from "react";


class ServicesBoxes extends React.Component {

    render() {
        return (
            <Grid container direction={'row'} alignItems={'center'} justify={'flex-start'} style={{ marginBottom: 5 }}>
                <Grid container direction='row' alignItems={'center'}
                    className={'sectionBodyStyle'}>
                    <i className='icon-time ico-blue' />
                    <i className='date_cal'>Services</i>
                </Grid>
                {(this.props.servicesJourProduit || []).map(service => {
                    return <Button

                        size={"small"}
                        variant={this.props.input_service_id === service.service_id && 'contained' || 'outlined'}
                        color={this.props.input_service_id === service.service_id && 'secondary' || 'primary'}

                        onClick={(_) => this.props.servHandleChange(service.service_id)}
                        key={service.service_id}
                        style={{ padding: '2.5px 5px', marginRight: 4 }}
                        disabled={service.fermeture > 0 || service.complet > 0}>{service.libelle}</Button>
                })}
                {
                    this.props.servicesJourProduit.find(serv => serv.service_id === this.props.input_service_id).description ?
                        <Grid container direction={'column'} alignItems={"stretch"}
                            className={"bg-desc sectionBodyStyle"} justify={'flex-start'} style={{ marginTop: 10 }}>
                            <Grid item className={'margin_bot'}>
                                <div className={'dangerous-desc'}
                                    dangerouslySetInnerHTML={{ __html: this.props.servicesJourProduit.find(serv => serv.service_id === this.props.input_service_id).description }} />
                            </Grid>
                        </Grid>
                        :
                        null}
            </Grid>
        );
    }
}

export default ServicesBoxes;