import Grid from "@material-ui/core/Grid";
import React from "react";
import ServicesBoxes from "./ServicesBoxes";
import ServiceInfo from "./ServiceInfo";

export default React.memo(props => {
    console.log(props)
    return (
        <Grid container direction={'row'}>
            <Grid container direction={'row'} alignItems={'center'}>
                <ServicesBoxes input_service_id={props.input_service_id}
                    servicesJourProduit={(props.jour || []).filter(el => el.produit_id === props.input_produit_id)}
                    servHandleChange={props.servHandleChange} />
            </Grid>
            <Grid container direction={'column'}>
                <ServiceInfo service={(props.jour || []).filter(el => el.service_id === props.input_service_id)[0]}
                    comboBoxHandleChange={props.comboBoxHandleChange} input_heure={props.input_heure} />

            </Grid>
        </Grid>
    );
})