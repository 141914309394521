import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/* Affichage d'un service */
export default props => {
    return (
        /*Affichage du titre du service et de la description si le service n'est pas complet
        * Chaque élément qui est disponible est affecté par cette première partie
        * */
        <Grid container
            direction="column">{(props.servicesJourProduit || []).map((service, j) => ((service.fermeture === 0 || service.fermeture === null) && service.complet === 0) ? (
                <Grid key={service.id + '_' + j} container direction={"column"}>
                    <Grid container direction="row" className={'sectionBodyStyle'} alignItems="stretch"
                        justify={"flex-start"} alignContent={"flex-start"}>
                        <Grid item>
                            <i className='ico-blue icon-time' /><i
                                className={'date_cal margin_bot'}>&nbsp;{service.libelle}</i>
                        </Grid>
                    </Grid>
                    {
                        service.description ?
                            <Grid container direction={'column'} alignItems={"stretch"}
                                className={"bg-desc sectionBodyStyle"} justify={'flex-start'}>
                                <Grid item className={'margin_bot'}>
                                    <div className={'dangerous-desc'}
                                        dangerouslySetInnerHTML={{ __html: service.description }} />
                                </Grid>
                            </Grid>
                            :
                            null
                    }
                    <Grid container direction={'row'} className={'buttonHourGroup sectionBodyStyle'}
                        style={{ margin: 0, padding: 0 }}>
                        {
                            service.horaires.slice(0, 7).map((horaire, i) => {
                                return <Grid item key={horaire.heure + ('_' + i)} style={{
                                    width: '25%',
                                    padding: '2px 0px',
                                    paddingRight: ((i + 1) % 4 !== 0 && 2) || 0,
                                    paddingLeft: (i % 4 !== 0 && 2) || 0,
                                }}>
                                    <Button id={'hr' + horaire.heure} m={20}
                                        size={"small"}
                                        variant={((horaire.heure === props.input_heure) && (service.service_id === props.input_service_id)) && 'contained' || 'outlined'}
                                        color={((horaire.heure === props.input_heure) && (service.service_id === props.input_service_id)) && 'secondary' || 'primary'}
                                        fullWidth
                                        onClick={props.typeHandleChange(service.horaires.find(h => h.heure === horaire.heure), service.service_id)} // service.libellé change id
                                        disabled={horaire.fermee === 1}>{horaire.heure}
                                    </Button>
                                </Grid>
                            })
                        }
                        {
                            service.horaires.length > 8 && <Grid item style={{
                                width: '25%',
                                padding: '2px 0px',
                                paddingLeft: 2,

                            }}>
                                <Select
                                    value={service.horaires.slice(7).find(serv => props.input_heure === serv.heure) ? props.comboHoraire : "default"}
                                    onChange={props.comboBoxHandleChange}
                                    fullWidth
                                    variant={(service.horaires.slice(7).find((horaire) => horaire.heure === props.input_heure)) && 'filled' || 'outlined'}
                                    style={
                                        (service.horaires.slice(7).find((horaire) => horaire.heure === props.input_heure)) &&
                                        {
                                            color: 'white',
                                            backgroundColor: 'rgb(0,159,140)',
                                            textAlign: 'center',
                                        }
                                        ||
                                        {
                                            color: 'rgb(0,44,66)',
                                            textAlign: 'center',
                                        }
                                    }
                                    inputProps={{ style: { textAlign: 'center' } }}
                                >
                                    <MenuItem disabled value={"default"} key={"default"}>...</MenuItem>
                                    {service.horaires.slice(7).map((horaire, i) => {
                                        return (<MenuItem id={'hr' + horaire.heure} m={20}
                                            value={horaire.heure}
                                            key={horaire.heure + '_' + i}
                                            disabled={service.horaires.find(el => el.heure === horaire.heure).fermee === 1}
                                        >{horaire.heure}</MenuItem>)
                                    })}
                                </Select>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            ) :       /* Cette partie est rendered pour les éléments qui sont fermés/réservés */
                ((service.fermeture > 0 || service.complet > 0) &&
                    <Grid container direction={"column"}>
                        <Grid container className={" sectionBodyStyle"} direction="row" alignItems="stretch"
                            justify={"flex-start"} alignContent={"flex-start"}>
                            <Grid item className={'margin_bot'}>
                                <i className='ico-blue icon-time' /><i
                                    className={'date_cal margin_bot'}>&nbsp;{service.libelle}</i>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} alignItems={"center"}
                            className={"bg-desc-unavailable sectionBodyStyle"} justify={'flex-start'}>
                            <Grid item className={"margin_bot dangerous-desc"}>
                                {(service.fermeture > 0 || service.complet > 0) ?
                                    (service.complet > 0 ? <p>Service complet</p> :
                                        (service.fermeture === 1 ?
                                            <p>Service fermé</p> : <p>Service fermé à la réservation</p>))
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: service.description }} />}
                            </Grid>
                        </Grid>
                    </Grid>)
            )}
        </Grid>);
}
