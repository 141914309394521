import React, { useState } from "react";
import { Button, ClickAwayListener, Grid, Input, Tooltip } from '@material-ui/core'
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles({
    tooltip: {
        padding: 0
    },
});

const generateArray = (max) => Array(Math.trunc(max)).fill(0);

const Couvert = props =>
    <Grid item style={{
        width: '20%',
        padding: '1px 0px',
        paddingRight: (((props.quantite % 5) !== 0) && 1) || 0,
        paddingLeft: ((((props.quantite - 1) % 5) !== 0) && 1) || 0,
    }}>
        <Button
            fullWidth
            size={"small"}
            variant={props.quantite === props.value && 'contained' || 'outlined'}
            color={props.quantite === props.value && 'secondary' || 'primary'}
            disabled={props.disabled}
            onClick={_ => props.onChange(props.quantite, 'quantite')}>{props.quantite}
        </Button>
    </Grid>;


const CouvertOver = props => {
    const [open, setOpen] = useState(false);
    const [quantite, setQuantite] = useState(((props.quantite < (props.min + 1)) && (props.min + 1)) || (props.quantite && props.max) || quantite);

    const onChangeQuantiteOver = (quantite) => {
        quantite = ((typeof quantite === 'string') && parseInt(quantite)) || quantite;
        quantite = ((quantite > props.max) && props.max) || quantite;
        setQuantite(quantite)
    };

    const classes = useStyles();

    return <Grid item style={{
        width: '20%',
        padding: '1px 0px',
        paddingRight: (((props.quantite % 5) !== 0) && 1) || 0,
        paddingLeft: ((((props.quantite - 1) % 5) !== 0) && 1) || 0,
    }}>
        <Tooltip
            margin={"dense"}
            id="couvtooltip"
            open={open}
            disableHoverListener
            classes={{ tooltip: classes.tooltip }}
            onClick={_ => {
                setOpen(true);
            }}
            title={
                <ClickAwayListener onClickAway={_ => {
                    if (!quantite) {
                        props.onChange((props.min + 1), 'quantite')
                    } else if (parseInt(quantite) > props.max) {
                        props.onChange(props.max, 'quantite')
                    } else {
                        props.onChange(parseInt(quantite), 'quantite')
                    }
                    setOpen(false);
                }}>
                    <Input margin={"dense"}
                        id="inputcouv"
                        disableUnderline={true}
                        value={quantite}
                        onChange={(e) => !isNaN(parseInt(e.target.value)) ? setQuantite(parseInt(e.target.value)) : null}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        startAdornment={
                            <InputAdornment position="start">
                                <Button
                                    style={{
                                        minHeight: 37
                                    }}
                                    size={"small"}
                                    variant={'contained'}
                                    color={'secondary'}
                                    disabled={props.disabled || quantite <= (props.min + 1)}
                                    onClick={_ => onChangeQuantiteOver(quantite - 1)}>
                                    <i className={'fa fa-caret-left'}
                                        style={{
                                            fontSize: 15,
                                            padding: 5
                                        }} />
                                </Button>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <Button
                                    style={{
                                        minHeight: 37
                                    }}
                                    size={"small"}
                                    variant={'contained'}
                                    color={'secondary'}
                                    disabled={props.disabled || quantite >= props.max}
                                    onClick={_ => onChangeQuantiteOver(quantite + 1)}>
                                    <i className={'fa fa-caret-right'}
                                        style={{
                                            fontSize: 15,
                                            padding: 5
                                        }} />
                                </Button>
                            </InputAdornment>
                        } />
                </ClickAwayListener>
            } interactive>
            <Button
                fullWidth
                size={"small"}
                variant={props.quantite > props.min && 'contained' || 'outlined'}
                color={props.quantite > props.min && 'secondary' || 'primary'}
                disabled={props.disabled}>{(props.quantite > props.min) && props.quantite || '+'}
            </Button>
        </Tooltip>
    </Grid>
};


export default props => {
    return <Grid container direction='row'
        style={{ position: 'relative' }}>
        {props.defaultLayout && <>
            {
                (props.couverts && props.couverts.length > 0
                    && props.couverts || generateArray(props.max > 10 && 9 || props.max)).map((n, couvert) =>
                        <Couvert key={couvert} disabled={props.disabled} onChange={props.onChange} quantite={couvert + 1}
                            value={props.value} />)
            }
            {
                props.max > 10 &&
                <CouvertOver min={9} max={props.max} disabled={props.disabled} onChange={props.onChange} quantite={props.value} />
            }
        </> || <CouvertOver min={0} max={props.max} disabled={props.disabled} onChange={props.onChange} quantite={props.value} />}
    </Grid>
}
