import {Badge, withStyles} from "@material-ui/core";

export default withStyles(theme => ({
    badge: {
        top: '75%',
        right: '50%',
        // The border color match the background color.
        border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
    },
    dot: {
        height: 7.5,
        width: 7.5
    }
}))(Badge);
