import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './normalize';
import * as serviceWorker from './serviceWorker';
import './styles/css/index.css';

const materialTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: 'rgb(0,44,66)'
        },
        secondary: {
            main: 'rgb(0,159,140)'
        },
        error: {
            main: 'rgb(0,159,140)'
        }
    },
    spacing: 5,
    overrides: {
        MuiButton: {
            root: {
                fontWeight: "bold",
                color: 'white',
                marginBottom: 0,
                minWidth: 40,
                minHeight: 40,
            },
            sizeSmall: {
                fontSize: '.9rem'
            }
        },
        MuiTooltip: {
            popper: {
                borderColor: 'rgb(0,44,66)',
                //backgroundColor: "white",
            },
            tooltip: {
                backgroundColor: "white",
                color: "rgb(0,44,66)",
                fontSize: 20,
                border: '1px solid rgb(0,44,66)'
            },
        },
        MuiInput: {
            underline: {
                border: '1px solid '.concat('rgb(0,44,66)'),
                borderBottom: 'none',
                borderRadius: 5,
                '&:after': {
                    borderBottom: 'none'
                },
            },
            root: {
                padding: '4px 8px',
            },
            input: {
                //color: '#53657D',
                "&::placeholder": {
                    marginTop: 10,
                    fontSize: 14
                }
            },
        },
        MuiOutlinedInput: {
            root: {
                maxHeight: 40
            }
        },
        MuiSelect: {
            root: {
                fontWeight: "bold",
                marginBottom: 0,
                minWidth: 40,
                fontSize: '.9rem'
            },
        },
        MuiMenu: {
            paper: {
                top: '65px',
            }
        },
        MuiTypography: {
            body1: {
                color: 'rgb(0,44,66)',
                fontSize: 17,
            }
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                margin: '0px 2.5px',
            }
        },
        MuiRadio: {
            root: {
                color: 'rgb(0,44,66)',
                '&$disabled': {
                    color: '#53657D',
                },
                "&$checked": {
                    color: "rgb(0,44,66)"
                }
            },
        },
        MuiFormControlLabel: {
            root: {
                marginRight: 0,
            },
        },
        FormControlLabel: {
            label: {
                color: 'rgb(0,44,66)',
                fontSize: 30,
            }
        },
        MuiInputBase: {
            inputMultiline: {
                width: '100%',
                border: '1px solid rgb(0,44,66)',
                borderRadius: 3,
                paddingLeft: 10,
                paddingTop: 3
            }
        },
        MuiFilledInput: {
            input: {
                padding: 10
            }
        },
        MuiPickersDay: {
            dayDisabled: {
                color: 'white',
                backgroundColor: '#F05D5E',
                border: 'none',
            },
            day: {
                color: '#53657D',
                borderRadius: 5,
                border: '1px solid '.concat('rgb(0,44,66)'),
                margin: 2.5,
                width: 32.5,
                height: 32.5
            },
            current: {
                color: '#53657D',
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                minHeight: 'none',
                minWidth: 'auto',
                padding: '10px 10px 20px 10px',
                justifyContent: 'flex-start'
            }
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={materialTheme}>
        <App />
    </MuiThemeProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
