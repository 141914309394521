import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    label: {
        color: "rgb(0,44,66)",
        fontSize: 20,
        border: '1px solid rgb(0,44,66)'
    },
    popper: {
        backgroundColor: "rgba(0, 0, 0, 0)"
    }
});

export default props => {

    const classes = useStyles();

    return <Grid container direction={'row'}
        style={{ margin: 0, padding: 0, marginBottom: 5 }}>
        {(props.produits || []).map((prod, i) => {
            const disabled = !(props.produits_jour || []).find(p => p === prod.id);
            return <Grid item key={prod.id} style={{
                width: '50%',
                padding: '2px 0px',
                paddingRight: ((((i + 1) % 2) !== 0) && 2) || 0,
                paddingLeft: (((i % 2) !== 0) && 2) || 0,
            }}>
                <Tooltip title={prod.libelle} classes={{ tooltip: classes.label, popper: classes.popper }}>
                    <Button fullWidth
                        size={'small'}
                        variant={props.input_produit_id === prod.id && 'contained' || 'outlined'}
                        color={props.input_produit_id === prod.id && 'secondary' || 'primary'}
                        style={disabled && {
                            backgroundColor: '#E1E1E1',
                            color: 'grey',
                            cursor: "not-allowed",
                            minHeight: 30
                        } || {}}
                        onClick={_ => !disabled && props.produitHandleChange(prod.id)}>
                        <Grid container alignItems={"center"} wrap={"nowrap"}>
                            <Grid item xs style={{
                                whiteSpace: 'nowrap',
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                paddingRight: 3,
                                paddingLeft: 3
                            }}>
                                {prod.libelle}
                            </Grid>
                            {
                                disabled &&

                                <Grid item style={{ zIndex: 100 }}>
                                    <Button
                                        size={'small'}
                                        onClick={_ => props.produitHandleChange(prod.id)}
                                        style={{
                                            fontSize: '0.8rem',
                                            minHeight: 20,
                                            borderRight: 'none',
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            position: 'relative',
                                            zIndex: 100,
                                            cursor: 'pointer'
                                        }}>
                                        <i className={'icon-calendar ico-white'} />
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Button>
                </Tooltip>
            </Grid>
        })}
    </Grid>
}
