import Avatar from '@material-ui/core/Avatar';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from "@material-ui/core/Paper";
import Popover from '@material-ui/core/Popover';
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import withWidth from '@material-ui/core/withWidth';
import Axios from 'axios';
import Moment from 'moment';
import 'moment/locale/fr';
import React, { Component } from 'react';
import Badge from "./Composants/Badge";
import BoxProduits from './Composants/BoxProduits.js';
import MyCalendar from './Composants/Calendrier.js';
import CouvertTab from './Composants/CouvertTab.js';
import InfoSupp from './Composants/InfoSupp.js';
import LayoutDefault from "./Composants/LayoutDefault";
import LayoutSimple from "./Composants/LayoutSimple";
import LogPaper from "./Composants/LogPaper";
import Menu from "./Composants/Menu";
import Payment from "./Composants/Payment";
import ReservationLine from "./Composants/ReservationLine";
import fr_flag from './img/france_flag.png';
import logo from './img/logo.png';
import en_flag from './img/uk_flag.png';
import './styles/css/App.css';
import './styles/css/philippe.css';
import { URLbase } from './utils/config';
import i18n from './utils/i18n';

const { extendMoment } = require('moment-range');
const moment = extendMoment(Moment);

moment.locale('fr')

const CancelToken = Axios.CancelToken;

class App extends Component {

    constructor(props) {
        super(props);

        const search = window.location.search.slice(1).split('&');
        const keyArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'key');
        const ifrArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'iframe');
        const langArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'lang');
        const minimalArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'minimal');
        const backgroundColorArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'background-color');
        const iframePaiementArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'iframePaiement');
        const dateArr = search.map(ser => ser.split('=')).filter(element => element[0] === 'date');
        if (keyArr.length > 0) {
            let keyObj = { 'key': keyArr[0][1] };
            let ifr = 'off', lang = 'fr', flag = fr_flag, minimal = false, date = moment().format('YYYY-MM-DD');
            let backgroundColor
            if (ifrArr.length > 0) {
                ifr = ifrArr[0][1]
            }
            if (langArr.length > 0) {
                lang = langArr[0][1];
                flag = (lang === "en" && en_flag) || fr_flag;
            }
            if (backgroundColorArr.length > 0) {
                backgroundColor = '#' + (backgroundColorArr[0][1] || 'F9F9F9');
            }
            if (minimalArr.length > 0) {
                minimal = minimalArr[0][1] === 'on';
            }
            if (dateArr.length > 0) {
                date = dateArr[0][1];
            }


            document.body.style.backgroundColor = backgroundColor || '#F9F9F9';

            this.state = {
                renderCode: 0,
                showMenuMobile: false,
                keyUrl: keyObj.key,
                urlbase: URLbase,
                token: '',
                input_quantite: 0,
                date: date,
                input_commentaire: "",
                isFirstMonth: true,
                startMonth: '',
                produits: [],
                goToLogin: false,
                resaConfirmed: false,
                userKnown: false,
                crea_resa_token: 0,
                cancelTokens: [],
                userForm_enableVerif: false,
                code_drop_down: false,
                isChoosingLang: false,
                payURL: "",
                flag: flag,
                lang: lang,
                step: 0,
                ifr: ifr,
                step_printed: 0,
                paymentActivated: false,
                paymentNeeded: false,
                defaultLayout: !minimal,
                comboHoraire: 'default',
                logInForm: {
                    email: '',
                    password: '',
                    showPassword: false,
                    last_email_valid: null,
                    last_password_valid: null,
                    userLoggedIn: false,
                    user_forgot_password: false,
                }
            }
        } else if (iframePaiementArr.length > 0) {
            this.state = {
                iframePaiement: iframePaiementArr[0][1] === 'true',
            }
        } else this.state = { key: null }
    }

    /*retourne la première heure du service ouverte*/
    new_getFirstHeureOuverte = (p_serv) => ((p_serv || {}).horaires || []).find(j => j.fermee === 0) || {};

    /* 3 returns différents, le produit lui meme si il a un menu, le prochain produit du jour ayant un menu, le produit lui meme */
    new_getProduitMenu = (jour, produits, prod_id) => {
        return produits.find(p => p.id === (((jour || []).find(j => j.produit_id === prod_id && j.menu !== null && j.menu !== '' && j.menu !== undefined) || {}).produit_id)) ||
            produits.find(p => p.id === (((jour || []).find(service => service.menu !== null && service.menu !== '' && service.menu !== undefined) || {})).produit_id) || [];
    };

    new_getProduitName = (id) => {
        return (this.state.produits.find(p => p.id === id) || {}).libelle;
    };


    get_Menuresa = () => {
        return (this.state.jour.find(serv => serv.service_id === this.state.input_service_id) || []).menu || '';
    };

    isProduitInJour = (prod_id) => {
        return (this.state.jour || []).find(j => j.produit_id === prod_id && j.fermeture === 0 && j.complet === 0) || false;
    };

    /* Vérification de la disponibilité de la date*/
    shouldDisableDate = (date) => !this.state.planning.find(p => p.date === date.format('YYYY-MM-DD') && !(p.fermeture > 0 || p.complet > 0));

    onMonthChange = async d => {
        const endMonth = d.endOf('month')
        if (endMonth.isBefore(moment())) return;
        let firstDay = d.startOf('month');
        const today = moment();
        firstDay = ((firstDay.isBefore(today)) && today) || firstDay;
        const planning = await this.getPlanning(false, firstDay.format('YYYY-MM-DD'), d.endOf('month').format('YYYY-MM-DD')).catch(err => console.log(err));
        const date = this.getFirstDateAvailable(planning || []);
        this.setState({
            planning: planning || [],
            ...date && await this.handleDate(date) || {}
        })
    };

    couvertHandleChange = (nbCouvert) => {
        if (nbCouvert === this.state.input_quantite) {
            if (document.getElementsByClassName("activated-bt-couv").length > 0) { // clear la classe ici sinon crash en cliquant sur un bouton d'horaire, changeant de date, et cliquant sur un autre bouton
                this.setState({ input_quantite: 0 })
            }
        } else {
            this.setState({ input_quantite: nbCouvert });
        }
    };

    comboBoxHandleChange = (e) => {
        this.setState({ input_heure: e.target.value, comboHoraire: e.target.value })
    };

    //aDD set state input_service_id
    typeHandleChange = (value, serv_id) => _ => {
        if (value.heure === this.state.input_heure) {
            if (document.getElementsByClassName("activated-bt-hrs").length > 0) { // clear la classe ici sinon crash en cliquant sur un bouton d'horaire, changeant de date, et cliquant sur un autre bouton
                let prevbt = document.getElementById('hr' + this.state.input_heure);
                prevbt.className = "buttonTileHour";
                this.setState({
                    input_heure: "",
                    input_quantite: this.input_quantite <= (this.state.jour || []).find(j => j.service_id === serv_id).reservation_quantite_reservable ? this.state.input_quantite : 0
                });
                return;
            }
        }

        this.setState({
            input_heure: value.heure,
            input_quantite: this.state.input_quantite <= (this.state.jour || []).find(j => j.service_id === serv_id).reservation_quantite_reservable ? this.state.input_quantite : 0,
            input_service_id: serv_id,
            couvert_max: (this.state.jour || []).find(serv => serv.service_id === serv_id).reservation_quantite_reservable || 20
        });
    };


    infoComplementairesHandleChange = (inf) => {
        this.setState({ input_commentaire: inf.target.value });
    };

    produitPlanningCheck = async (produit_id, date) => {
        let infos_plan = (await this.new_requeteAPI('restaurant', 'planning', 'list', this.state.token, {
            date_debut: moment(date).startOf('month').format('YYYY-MM-DD'),
            date_fin: moment(date).endOf('month').format('YYYY-MM-DD')
        })).results;
        let next_date = (infos_plan.find(plan => plan.produit_id === produit_id && !(plan.fermeture > 0 || plan.complet > 0) && moment(plan.date).isSameOrAfter(moment(this.state.input_date, 'YYYY-MM-DD').format('YYYY-MM-DD'))) || {}).date;
        if (next_date) {
            let infos_jour = (await this.new_requeteAPI('restaurant', 'planning', 'jour', this.state.token, {
                date: next_date,
            })).results;
            let infos = infos_jour.find(j => j.produit_id === produit_id && j.fermeture === 0 && j.complet === 0);
            const info = { infos_jour, infos, next_date, infos_plan };
            return info;
        } else {
            return await this.produitPlanningCheck(produit_id, moment(date).add(1, 'month'));
        }
    };
    //check si le produit est présent dans le jour, le mois ou le mois d'après et update le state
    produitHandleChange = async (produit_id) => {
        let infos;
        let next_date = this.state.input_date;
        let infos_jour = (this.state.jour || []);
        let infos_plan = this.state.planning || [];
        const service_jour = this.isProduitInJour(produit_id);
        if (service_jour) {
            infos = service_jour;
            next_date = service_jour.date;
        } else {
            let res = await this.produitPlanningCheck(produit_id, this.state.input_date);
            infos_jour = res.infos_jour;
            infos = res.infos;
            next_date = res.next_date;
            infos_plan = res.infos_plan;
        }

        if (infos) this.setState({
            input_date: next_date !== undefined ? next_date : moment(this.state.input_date, 'YYYY-MM-DD').add(1, 'month').startOf('month').format('YYYY-MM-DD'),
            input_produit_id: produit_id,
            input_service_id: infos.service_id,
            couvert_max: infos.reservation_quantite_reservable || 20,
            step_printed: 0,
            planning: infos_plan,
            jour: infos_jour,
            produitMenu: this.new_getProduitMenu(infos_jour, this.state.produits, produit_id),
            input_heure: this.new_getFirstHeureOuverte(infos).heure,
            input_quantite: this.state.input_quantite <= infos.reservation_quantite_reservable ? this.state.input_quantite : 0,
            paymentNeeded: (this.state.paymentActivated)
        })
    };

    menuListHandleChange = (event) => {
        let prod_id = this.state.input_produit_id;
        let serv_id = parseInt(event.target.value);
        const jour = ((this.state.jour || []).find(j => j.service_id === serv_id));
        let input_heure = jour.horaires.find(h => h.fermee === 0).heure;
        if (!(this.state.produitMenu.id === this.state.input_produit_id)) prod_id = this.state.produitMenu.id;
        this.setState({
            input_produit_id: prod_id,
            input_service_id: parseInt(event.target.value),
            input_heure: input_heure,
            input_quantite: this.state.input_quantite <= jour.reservation_quantite_reservable ? this.state.input_quantite : 0
        })
    };

    servHandleChange = (id) => {
        const first_hr = this.new_getFirstHeureOuverte(this.state.jour.find(j => j.service_id === id)).heure;
        this.setState({ input_service_id: id, input_heure: first_hr })
    };

    /* permet de récupérer les produits différents pour chaque jour (affichage du nb produit sur la page du menu*/
    dayDifferentsProduitsID = () => {
        const prod_serv = (this.state.jour || []).filter(service => service.menu !== null && service.menu !== '' && service.menu !== undefined);
        const prod_serv_ids = prod_serv.map(service => service.produit_id);
        const prod_id_unique = prod_serv_ids.filter((element, i) => prod_serv_ids.indexOf(element) >= i);
        return this.state.produits.filter(prod => prod_id_unique.includes(prod.id));
    };
    /*Handle les flèches sur le flèches du menu */
    menuArrowHandleClick = (btn) => {
        const tabProduitsJourID = this.dayDifferentsProduitsID();
        if (btn.target.id === 'prev') {
            if (tabProduitsJourID.indexOf(this.state.produitMenu) > 0) {
                this.setState({ produitMenu: tabProduitsJourID[tabProduitsJourID.indexOf(this.state.produitMenu) - 1] })
            } else {
                this.setState({ produitMenu: tabProduitsJourID[tabProduitsJourID.length - 1] })
            }
        } else if (btn.target.id === 'next') {
            if (tabProduitsJourID.indexOf(this.state.produitMenu) + 1 < tabProduitsJourID.length) {
                this.setState({ produitMenu: tabProduitsJourID[tabProduitsJourID.indexOf(this.state.produitMenu) + 1] })
            } else {
                this.setState({ produitMenu: tabProduitsJourID[0] })
            }
        }
    };

    /* masque le menu si aucun menu pour tous les produits*/
    allProduitsMenusareEmpty = (j) => {
        if (j) {
            const tab = j.map(serviceProduit => {
                if (serviceProduit.menu === '' || serviceProduit.menu === null || serviceProduit.menu === undefined) return null;
                else return serviceProduit
            });
            return tab.every(el => el === null);
        } else return null;
    };

    /* Affiche la modale de login */
    validateState = async () => {
        await this.new_requeteAPI("restaurant", "demande", "add", this.state.token, {
            date_reservation: this.state.input_date + ' ' + this.state.input_heure,
            //heure: this.state.input_heure,
            produit_id: this.state.input_produit_id,
            service_id: this.state.input_service_id,
            quantite: this.state.input_quantite,
            commentaire: this.state.input_commentaire,
            key: this.state.key
        }).then(res => {
            let menu = this.get_Menuresa();
            if (res.code === 1) {
                this.setState({
                goToLogin: true,
                crea_resa_token: res.id,
                step: 1,
                input_menuResa: menu
                });
            } else {
                alert(res.message)
            }

        })

    };

    returnToReservation = () => {
        this.setState({ goToLogin: false, step: 0, step_printed: 0 })
    }


    /********************************************************************************************
     *****************************Méthodes de la modale******************************************
     ********************************************************************************************/

    /* Handlers de modification des inputs */

    handleEmailChange = (event) => {
        this.setState({
            logInForm: { ...this.state.logInForm, email: (event.target.value || '').toLowerCase() },
            userForm: { ...this.state.userForm, contact: { ...this.state.userForm.contact, email: (event.target.value || '').toLowerCase() } }
        })
    };

    handlePasswordChange = (event) => {
        let log = this.state.logInForm;
        log.password = event.target.value;
        this.setState({ logInForm: log })
    };

    togglePassword = () => {
        let arrow = document.querySelector('.icon-arrow');
        if (this.state.showPassword) arrow.classList.remove('email-recognized');
        else arrow.classList.add('email-recognized');
    };

    handlePortableChange = (event) => {
        let form = this.state.userForm;
        let number;
        number = event.target.value.replace(/ /g, '').replace(/\D/g, '');
        form.contact.portable = number;
        if (number.length >= 10) this.setState({ userForm_enableVerif: true });
        else if (this.state.userForm_enableVerif === true) this.setState({ userForm_enableVerif: false });
        this.setState({ userForm: form });
    };

    handlePortableCodeChange = (event) => {
        let form = this.state.userForm;
        let rep;
        form.contact.phone_code = parseInt(event.target.value);
        if (event.target.value.length > 4) {
            if (this.state.timeout_phone_id) clearTimeout(this.state.timeout_phone_id);
            const clear = setTimeout(async () => {
                rep = await this.new_requeteAPI('commun', 'sms', 'verification', this.state.token, {
                    code: this.state.userForm.contact.phone_code,
                    reservation_id: this.state.crea_resa_token
                });
                form = this.state.userForm;
                if (rep.code === 1) {
                    form.phone_code_icon = 'icon-validate';
                    form.error.phone_code = this.verificationPortable ? null : false;
                } else form.phone_code_icon = 'icon-close';
                this.setState({ userForm: form, timeout_phone_id: null })
            }, 1000);
            this.setState({ timeout_phone_id: clear })
        }
    };

    /*handler du formulaire de réservation pour un utilisateur non connu */
    handleFormChange = (param, type) => (event) => {
        let form = this.state.userForm;
        if (type === 'box') {
            form.contact[param] = !form.contact[param];
            if (param === 'mentionsIsAccepted') form.error.mentionsIsAccepted = !form.contact[param];
        } else if (param === 'email' || param === 'confirm_email') {
            form.contact[param] = (event.target.value || '').toLowerCase()
        } else form.contact[param] = event.target.value;
        this.setState({ userForm: form })
    };

    /* bouton suivant sur le formulaire de réservation */
    nextOnclick = async () => {
        let form = this.state.userForm;
        let errorstab = Object.keys(form.contact).filter(i => form.error[i] !== false && !form.contact[i]);

        if (errorstab.length === 0) {
            let res = (await this.new_requeteAPI('restaurant', 'demande', 'finalisation', this.state.token, {
                id: this.state.crea_resa_token,
                contact: this.state.userForm.contact,
                showAlertError: true
            }));
            if (res.code === 1) {
                let url_pay = null;
                if (this.state.paymentNeeded) url_pay = res.url_paiement;
                else (url_pay = '');
                this.setState({
                    resaConfirmed: this.state.paymentNeeded === false,
                    message_fin_resa: res.message_fin_resa,
                    payURL: url_pay,
                    step: this.state.paymentNeeded === false ? 1 : 2,
                });
            }
        } else {
            let new_error = form.error;
            errorstab.map(e => new_error[e] = true);
            form.error = new_error;
            this.setState({ userForm: form })
        }
    };

    /* égalité mots de passes et emails */
    checkEquality = (param) => () => {
        let form = this.state.userForm;
        if (form.contact.email.toUpperCase() === form.contact.confirm_email.toUpperCase()) {
            form.error.email = false;
            form.error.confirm_email = false
        } else {
            form.error.email = true;
            form.error.confirm_email = true;
        }
        this.setState({ userForm: form })
    };

    /* fired quand on quitte un champ d'input (toggle les erreurs) */
    onExitInputField = (stateName) => () => {
        let form = this.state.userForm;
        if (form.contact[stateName] === '') form.error[stateName] = true;
        else form.error[stateName] = false;
        this.setState({ userForm: form });
        return null;
    };


    /* Fonctions de vérifications du format de données entrées */
    isValidEmail = () => {
        let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return this.state.logInForm.email.match(regex);
    };

    continueAsGuest = async () => {
        let log = this.state.logInForm;
        let us_k = false;
        if (log.showPassword) {
            log.showPassword = false;
            this.togglePassword()
        }
        log.userLoggedIn = true;
        log.last_email_valid = this.isValidEmail(log.email) !== null;
        this.setState({ LogInForm: log, userKnown: us_k, asGuest: 1 }, () => this.resize())
    }

    /* Fonction de vérification de cohérence des données (login form) */
    checkLogIn = async () => {
        let log = this.state.logInForm;
        let us_k = false;
        if (this.isValidEmail(log.email) === null) {
            if (log.showPassword) {
                log.showPassword = false;
                this.togglePassword();
            }
        } else {
            const res = (await this.new_requeteAPI('crm', 'user', 'login', this.state.token, {
                email: log.email,
                password: log.password || '',
                reservation_id: this.state.crea_resa_token
            }));
            if (res.code === 1) {
                log.last_password_valid = true;
                const conf = await this.new_requeteAPI('restaurant', 'demande', 'finalisation', res.token, { id: this.state.crea_resa_token });
                if (conf.code === 1) {
                    us_k = true;
                    let url_pay;
                    if (this.state.paymentNeeded) url_pay = conf.url_paiement;
                    else (url_pay = '');
                    this.setState({
                        resaConfirmed: this.state.paymentNeeded === false,
                        message_fin_resa: conf.message_fin_resa,
                        payURL: url_pay,
                        step: this.state.paymentNeeded === false ? 1 : 2,
                    })
                }
            } else if (res.code === -4) {
                if (!log.showPassword) {
                    const email = document.querySelector('#email-text').parentElement;
                    email.classList.remove('Mui-focused');
                    this.togglePassword();
                    log.showPassword = true;
                } //premiere fois
                if (log.last_password_valid === null) log.last_password_valid = 'rdy'; //aff error mail on last_password_value = false
                else {
                    log.last_password_valid = false;
                    let logForm = log;
                    logForm.error = (res.message !== 'Votre mot de passe n\'est pas valide' && res.message) || '';
                    this.setState({ logInForm: logForm });
                }
            } else {
                if (log.showPassword) {
                    log.showPassword = false;
                    this.togglePassword()
                }
                log.userLoggedIn = true;
            }
        }
        log.last_email_valid = this.isValidEmail(log.email) !== null;
        this.setState({ LogInForm: log, userKnown: us_k }, () => this.resize())
    };

    returnToLogIn = () => {
        let log = this.state.logInForm;
        log.userLoggedIn = false;
        log.password = ''
        this.setState({ LogInForm: log, asGuest: 0 })
    }

    closeLogIn = () => {
        const user = this.state.logInForm;
        user.userLoggedIn = false;
        this.setState({ userLoggedIn: user, userKnown: false, step: 0, })
    };

    closeResa = () => {
        this.setState({
            input_quantite: 0, input_commentaire: "", step: 0, step_printed: 0, payURL: '',
            goToLogin: false, resaConfirmed: false, userKnown: false, crea_resa_token: 0,
            logInForm: {
                email: '',
                password: '',
                showPassword: false,
                last_email_valid: true,
                last_password_valid: null,
                userLoggedIn: false,
                user_forgot_password: false,
            },
            userForm: {
                contact: {
                    nom: '',
                    prenom: '',
                    email: '',
                    portable: '',
                    civilite_id: 1,
                    exclus_newsletter: 0,
                    sauvegarder: 0,
                    mentionsIsAccepted: 0,
                    confirm_email: '',
                    phone_code: ''
                },
                error: {
                    nom: null,
                    prenom: null,
                    email: null,
                    portable: null,
                    sauvegarder: false,
                    exclus_newsletter: false,
                    phone_code: this.verificationPortable ? null : false,
                    confirm_email: null,
                    mentionsIsAccepted: null
                },
                enableVerif: true
            }
        })
    };

    getServiceLibelle = () => {
        if (this.state.input_service_id) return this.state.services.find(sr => sr.id === this.state.input_service_id).libelle
    };

    onClickVerifPhone = async () => {
        if (this.state.userForm.contact.portable.length >= 10) {
            this.setState({ userForm_enableVerif: 'cooldown', code_drop_down: true });
            setTimeout(() => this.setState({ userForm_enableVerif: true }), 5000);
            let code = (await this.new_requeteAPI('commun', 'sms', 'code', this.state.token, {
                portable: this.state.userForm.contact.portable,
                reservation_id: this.state.crea_resa_token,
            })).code;
            let form = this.state.userForm;
            this.setState({ userForm: form });
        }
    };

    toggleIframe = () => {
        const log = this.state.logInForm;
        log.user_forgot_password = !log.user_forgot_password;
        this.setState({ logIn: log })
    };


    new_requete_API_Cancelable = async (module, typerequete, act, token, params) => {
        let key = CancelToken.source();
        const elem = this.state.cancelTokens;
        elem.map((token, i) => {
            if (token !== key) {
                elem.splice(i, i + 1);
                let a = token.cancel('subrequest done');
            }
        });
        elem.push(key);
        this.setState({ cancelTokens: elem });
        const param = { requete: typerequete, action: act, key: this.state.keyUrl };
        Object.assign(param, params);
        const urlToUse = this.state.urlbase + module;
        const resdata = await Axios.post(urlToUse, param, {
            headers: { Authorization: this.state.token || token || '' },
            cancelToken: key.token
        });
        return resdata.data;
    };

    new_requeteAPI = async (module, typerequete, act, token, params) => {
        const param = {
            requete: typerequete,
            action: act,
            lang: ((this.state.lang === 'fr') && 1) || 2
        };

        const showAlertError = params && params.showAlertError
        params && delete params.showAlertError
        Object.assign(param, params);
        const urlToUse = this.state.urlbase + module;
        const resdata = await Axios.post(urlToUse, param, { headers: { Authorization: token || this.state.token || '' } });
        if (showAlertError && resdata.data && resdata.data.code < 1) {
            this.setState({
                apiError: resdata.data
            })
        }
        return resdata.data;
    };

    toggleLanguage = () => {
        this.setState({ isChoosingLang: true })
    };

    selectLang = (flag, lang) => async () => {
        i18n.changeLanguage(lang);
        this.setState({
            flag: flag,
            lang: lang,
        }, async _ => {
            const etablissement = await this.getEtsInfo();
            const jour = await this.getDay(this.state.input_date, etablissement.produits, etablissement.services);
            this.setState({
                isChoosingLang: false,
                produits: etablissement.produits,
                services: etablissement.services,
                entete_resto: etablissement.entete,
                defaultLayout: !etablissement.minimal_layout || (this.state.defaultLayout && this.props.width !== 'xs'),
                jour: jour
            });
        })
    };

    paymentSuccess = () => {
        this.setState({ resaConfirmed: true })
    }


    getPlanning = async (getfirstAvailable, date_debut, date_fin, token, produits = this.state.produits, services = this.state.services) => {
        const planning = (await this.new_requete_API_Cancelable('restaurant', 'planning', 'list', token, {
            date_debut, date_fin
        })).results;
        const rangeDates = moment.range(date_debut, date_fin)
        const dates = Array.from(rangeDates.by('days'));


        const newPlanning = dates.map(date => {
            return produits.map(produit => {
                return services.filter(service => service.produit_id === produit.id).map(service => {
                    const day = planning.find(p => date.isSame(p.date) && p.produit_id === produit.id && p.service_id === service.id)
                    return {
                        date: date.format('YYYY-MM-DD'),
                        fermeture: day ? day.fermeture : 0,
                        complet: day ? day.complet : 0,
                        produit_id: produit.id,
                        service_id: service.id,
                        reservation_couverts_max: day ? day.reservation_quantite_reservable : produit.reservation_couverts_max
                    }
                })
            }).reduce((a, b) => [...a, ...b], [])
        }).reduce((a, b) => [...a, ...b], [])

        return newPlanning.find(p => !(p.fermeture > 0 || p.complet > 0)) && newPlanning
            || getfirstAvailable && moment(date_debut).isBefore(moment().startOf('month').add(1, 'years')) && await this.getPlanning(true, moment(date_debut).add(1, 'month').startOf('month').format('YYYY-MM-DD'),
                moment(date_fin).add(1, 'month').endOf('month').format('YYYY-MM-DD'), token, produits, services)
    };

    getEtsInfo = async (token) => (await this.new_requeteAPI('restaurant',
        'configuration',
        'get', token,
        {
            type: 1,
        })).results;

    getFirstDay = async token => (await this.new_requeteAPI('restaurant', 'planning', 'first_day', token, {
    })).results;

    getFirstDateAvailable = (planning) => ((planning || []).find(p => !(p.fermeture > 0 || p.complet > 0)) || {}).date;

    getDay = async (date, produits = this.state.produits, services = this.state.services) => {
        const servicesDay = (await this.new_requeteAPI('restaurant', 'planning', 'jour', this.state.token, {
            date: date,
        })).results;


        const day = produits.map(produit => {
            return services.filter(service => service.produit_id === produit.id).map(service => {
                const d = servicesDay.find(p => p.produit_id === produit.id && p.service_id === service.id) || {}
                return {
                    date: date,
                    fermeture: moment().isBefore(moment(date + ' ' + service.horaires[0]).add(service.duree_limite_fermeture, 'hours')) ? 0 : 1,
                    complet: 0,
                    horaires: service.horaires.map(horaire => ({
                        heure: horaire,
                        fermee: 0,
                        couverts: []
                    })),
                    libelle: service.libelle,
                    produit_id: produit.id,
                    service_id: service.id,
                    reservation_couverts_max: produit.reservation_couverts_max,
                    ...(d || {}),
                }
            })
        }).reduce((a, b) => [...a, ...b], [])

        return day.some(service => !service.fermeture) ? day : this.getDay(moment(date).add(1, 'days').format('YYYY-MM-DD'), produits, services)
    }

    getFirstProduitServiceAvailable = (jour, produit_id) => jour.find(p => !(p.fermeture > 0 || p.complet > 0) && produit_id === p.produit_id) || jour.find(p => !(p.fermeture > 0 || p.complet > 0));

    async componentDidMount() {
        if (this.state.iframePaiement) {
            window.top.postMessage({
                paiement: true
            },
                '*'
            );
        } else {
            const token = (await this.new_requeteAPI('crm', 'etablissement', 'token', undefined, {
                key: this.state.keyUrl,
            })).token;
            if (token === undefined) alert('Etablissement non reconnu.');

            const etablissement = await this.getEtsInfo(token);

            if (etablissement.produits === undefined) {
                alert('Pas de produits pour cet établissement.');
                return
            }
            if (etablissement.services === undefined) {
                alert('Pas de services pour cet établissement.');
                return
            }

            const planning = await this.getPlanning(true, (moment(this.state.date).startOf('month').isSameOrAfter(moment()) ? moment(this.state.date).startOf('month') : moment()).format('YYYY-MM-DD'), moment(this.state.date).endOf('month').format('YYYY-MM-DD'), token, etablissement.produits, etablissement.services) || []

            const firstDay = planning && planning.length > 0 && (planning.find(p => p.fermeture === 0 && p.date === this.state.date) || planning.find(p => p.fermeture === 0) || {}).date

            this.setState({
                token: token,
                entete_resto: etablissement.entete,
                produits: etablissement.produits,
                services: etablissement.services,
                planning: planning,
                paymentActivated: (etablissement.prepaiement),
                verificationPortable: etablissement.verificationPortable,
                userForm: {
                    contact: {
                        nom: '',
                        prenom: '',
                        email: '',
                        portable: '',
                        civilite_id: 1,
                        exclus_newsletter: 0,
                        sauvegarder: 0,
                        mentionsIsAccepted: 0,
                        confirm_email: '',
                        phone_code: '',
                    },
                    error: {
                        nom: null,
                        prenom: null,
                        email: null,
                        portable: null,
                        sauvegarder: false,
                        exclus_newsletter: false,
                        phone_code: etablissement.verificationPortable ? null : false,
                        confirm_email: null,
                        mentionsIsAccepted: null
                    }
                }
            }, async _ => {
                this.setState({
                    renderCode: 1,
                    ...(firstDay) ? await this.handleDate((firstDay)) : {}
                }, _ => {
                    this.selectLang(this.state.lang, this.state.flag)

                    setTimeout(function () {
                        if (firstDay) {
                            console.log(firstDay, planning)
                            const cal = document.querySelector('#my-calendar-unique');
                            cal && cal.click();
                        }
                    }, 450);
                })
            })
        }

    }

    handleDate = async date => {
        let { input_produit_id } = this.state;

        const jour = await this.getDay(date);
        const p_jour = this.getFirstProduitServiceAvailable(jour, input_produit_id) || {};

        return {
            input_date: jour[0] && jour[0].date || date,
            input_produit_id: p_jour.produit_id,
            input_service_id: p_jour.service_id,
            produitMenu: this.new_getProduitMenu(jour, this.state.produits, input_produit_id),
            jour: jour,
            input_heure: this.new_getFirstHeureOuverte(p_jour).heure,
            couvert_max: p_jour.reservation_quantite_reservable || 20,
            cancelTokens: [],
            input_quantite: this.state.input_quantite <= p_jour.reservation_quantite_reservable ? this.state.input_quantite : 0,
            paymentNeeded: this.state.paymentActivated
        }
    };


    calendrierHandleChange = async (date) => {
        this.setState({
            ...await this.handleDate(moment(date).format('YYYY-MM-DD')) || {}
        })
    };

    resize = () => {
        let shouldRerender = false;
        const element = document.getElementById('main-grid-reservation');
        if (element) {
            const childs = Array.prototype.slice.call(element.childNodes).filter(c => c && c.style && c.style.visibility !== 'hidden').slice(-1)[0];
            shouldRerender = this.height !== (childs && childs.clientHeight);
            this.height = (childs && childs.clientHeight);
            element.style.height = 'calc(' + this.height + "px - (" + (childs && childs.style.padding) + ' * 2))'; //1st client height is same so no change after 1st (expands after min height tho
        }
        shouldRerender && this.forceUpdate()
    };

    height = -100;

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.state.step < 2 ? this.resize() : (this.state.paymentNeeded && this.resize())
    }

    deletePaper = (nb) => {
        this.setState({ step_printed: nb }, () => window.scrollTo(0, 0));
    };

    render() {
        return (
            this.state.key === null &&
            <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                <img src={logo} />
                <h1 style={{ marginTop: 20 }}>Entrez une clé valide dans l'URL.</h1>
            </Grid> ||
            this.state.renderCode === 0 && <div /> ||
            <Grid container direction={"row"} alignItems={"flex-start"} justify={"center"} spacing={0}
                    style={{
                        padding: this.props.width === 'xs' && this.state.showMenuMobile ? "60px 40px 20px 20px" :
                            (this.props.width === 'xs' ? '70px 70px 20px 30px' : 70),
                    }}>
                <Grid container direction={"row"} alignItems={'flex-start'} justify={'center'}>
                    {this.state.ifr !== 'on' && <Grid item>

                        <Avatar style={{ position: 'fixed', top: 10, right: 10 }} src={this.state.flag}
                            className={'lang-ic'} onClick={this.toggleLanguage} />
                        <Popover
                            anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                            PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: "none" } }}
                            open={this.state.isChoosingLang}
                            onClose={this.toggleLanguage}
                            anchorEl={document.querySelector('.lang-ic')}
                        >{<Avatar src={this.state.lang === 'fr' && en_flag || fr_flag} className={`lang-ic`}
                            onClick={this.selectLang(this.state.lang === 'fr' && en_flag || fr_flag, this.state.lang === 'fr' && 'en' || 'fr')} />}
                        </Popover>
                        </Grid>}
                        {this.state.entete_resto && this.state.entete_resto !== undefined && this.state.entete_resto !== 'undefined' && this.state.ifr !== "on" &&
                            <Hidden mdDown={this.allProduitsMenusareEmpty(((this.state.jour || []) || [])) ? false : true} smDown>
                            <Grid item lg={3} xl={3}>
                                    <Paper style={{
                                        margin: this.props.width === 'xs' ? 0 : '0px 40px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    border: '1px solid rgb(0,44,66)'
                                }} elevation={10}>
                                    <div id='etabl' dangerouslySetInnerHTML={{ __html: this.state.entete_resto }} />
                                </Paper>
                            </Grid></Hidden>}
                        {!this.state.showMenuMobile && moment().add(1, 'year').isAfter(moment(this.state.input_date, 'YYYY-MM-DD')) &&
                        <Grid item xs={12} sm={8} md={4} lg={3} xl={3}>
                            <Grid id={'main-grid-reservation'} item style={{
                                    position: "relative",
                                    margin: this.props.width === 'xs' ? 0 : '0px 40px',
                            }}>
                                {this.state.apiError &&
                                    <Slide key={this.state.apiError} in={this.state.apiError} direction={'right'}>
                                        <Grid container
                                            justify={"center"}
                                            alignItems={"center"}
                                            alignContent={"center"}
                                            onClick={_ => this.setState({ apiError: undefined })}
                                            style={{
                                                fontSize: 13,
                                                boxShadow: '1px 1px 3px red',
                                                border: '1px solid red',
                                                position: 'absolute',
                                                color: 'red',
                                                left: -5,
                                                top: -50,
                                                padding: '10px 10px',
                                                borderRadius: 10,
                                                width: 'calc(100% + 20px)',
                                                cursor: 'pointer'
                                            }}>
                                            <i className={'fa fa-times'} style={{
                                                border: '1px solid red',
                                                borderRadius: '50%',
                                                padding: '5px 7.5px'
                                            }} />&nbsp;
                                    <span>{this.state.apiError && this.state.apiError.message || ''}</span>
                                        </Grid>
                                    </Slide>}
                                    {this.state.paymentNeeded && this.state.step_printed <= 2 ?
                                    <Slide in={this.state.paymentNeeded} direction={'up'} onExited={() => this.state.step_printed === 2 && this.deletePaper(3)}>
                                        <Paper id="PayPaper" className={`${this.state.step !== 2 && 'paperBlur'}`} style={{
                                            zIndex: 1,
                                            borderRadius: '10px',
                                            border: '1px solid rgb(0,44,66)',
                                            width: "100%",
                                            minHeight: this.state.step < 2 && '100%',
                                            position: "absolute",
                                            left: 0,
                                            padding: '10px',
                                            top: 0
                                        }}
                                            elevation={this.state.step === 2 ? 0 : 1}>

                                            <Payment payURL={this.state.payURL} resaConfirmed={this.state.resaConfirmed}
                                                paymentSuccess={this.paymentSuccess} closeResa={this.closeResa}
                                                message_fin_resa={this.state.message_fin_resa} />
                                            {this.state.step <= 3 && <Tooltip title={"Prépaiement"} placement={"right"}>
                                                <Button style={{
                                                    position: 'absolute',
                                                    right: this.state.step === 2 ? -41 : -40,
                                                    top: 11,
                                                    minWidth: 15,
                                                    minHeight: 15
                                                }} size={"small"} variant={this.state.step === 2 && 'contained' || 'outlined'}
                                                    color={this.state.step === 2 && "secondary" || 'primary'}>
                                                    <i className={`icon-commun_paiement`} />
                                                </Button>
                                            </Tooltip>}
                                            {this.state.step === 3 &&
                                                <Grid container justify={"flex-end"} alignContent={"center"} alignItems={"center"}
                                                    style={{ position: 'absolute', right: 10, bottom: -20, fontSize: 12 }}>
                                                    <a href={'https://euresto.com'}
                                                        target={'_blank'}
                                                        className={'fa fa-bolt'}
                                                        style={{
                                                            textDecoration: 'none',
                                                            color: '#FDFD00',
                                                            textShadow: '1px 1px 2px black'
                                                        }} />
                                        &nbsp;<span>by Euresto</span>
                                                </Grid>}
                                        </Paper>
                                        </Slide> : null}
                                {this.state.step_printed <= 1 &&
                                    <Slide in={this.state.step <= 1} direction={'up'} onExited={() => this.deletePaper(2)}>
                                        <Paper id="LogPaper" className={`${this.state.step !== 1 && 'paperBlur'}`} style={{
                                            zIndex: 2,
                                            borderRadius: '10px',
                                            border: '1px solid rgb(0,44,66)',
                                            width: "100%",
                                            position: "absolute",
                                            top: 15,
                                            left: -10,
                                            minHeight: this.state.step < 1 && '100%',
                                            padding: 10,
                                        }}>
                                            <React.Fragment>
                                                <Tooltip title={"Contact"} placement={"right"} style={{ top: 22 }}>
                                                    <Button style={{
                                                        position: 'absolute',
                                                        right: this.state.step === 1 ? -41 : -40,
                                                        top: 22,
                                                        minWidth: 15,
                                                        minHeight: 15
                                                    }} size={"small"}
                                                        variant={this.state.step === 1 && 'contained' || 'outlined'}
                                                        color={this.state.step === 1 && "secondary" || 'primary'}>
                                                        <i className={`icon-simple_user`} />
                                                    </Button>
                                                </Tooltip>
                                                <LogPaper
                                                    userForm={this.state.userForm}
                                                    verificationPortable={this.state.verificationPortable}
                                                        logInForm={this.state.logInForm}
                                                        asGuest={this.state.asGuest}
                                                    checkEquality={this.checkEquality}
                                                    handleFormChange={this.handleFormChange}
                                                    nextOnclick={this.nextOnclick}
                                                    onExitInputField={this.onExitInputField}
                                                    handleEmailChange={this.handleEmailChange}
                                                    handlePasswordChange={this.handlePasswordChange}
                                                    togglePassword={this.togglePassword}
                                                    onClickVerifPhone={this.onClickVerifPhone}
                                                    isValidEmail={this.isValidEmail}
                                                    userForm_enableVerif={this.state.userForm_enableVerif}
                                                        checkLogIn={this.checkLogIn}
                                                        continueAsGuest={this.continueAsGuest}
                                                    handlePortableChange={this.handlePortableChange}
                                                    closeLogIn={this.closeLogIn} closeResa={this.closeResa}
                                                    userKnown={this.state.userKnown}
                                                    handlePortableCodeChange={this.handlePortableCodeChange}
                                                    input_date={moment(this.state.input_date).format('DD-MM-YY')}
                                                    message_fin_resa={this.state.message_fin_resa}
                                                    code_drop_down={this.state.code_drop_down}
                                                    service_libelle={this.getServiceLibelle()}
                                                    heure_resa={this.state.input_heure}
                                                    quantite={this.state.input_quantite}
                                                    resaConfirmed={this.state.resaConfirmed}
                                                    payURL={this.state.payURL}
                                                    toggleIframe={this.toggleIframe}
                                                    returnToReservation={this.returnToReservation}
                                                    returnToLogIn={this.returnToLogIn}
                                                    step={this.state.step} />
                                            </React.Fragment>
                                            {this.state.step === 1 &&
                                                <Grid container justify={"flex-end"} alignContent={"center"} alignItems={"center"}
                                                    style={{ position: 'absolute', right: 10, bottom: -20, fontSize: 12 }}>
                                                    <a href={'https://euresto.com'}
                                                        target={'_blank'}
                                                        className={'fa fa-bolt'}
                                                        style={{
                                                            textDecoration: 'none',
                                                            color: '#FDFD00',
                                                            textShadow: '1px 1px 2px black'
                                                        }} />
                                        &nbsp;<span>by Euresto</span>
                                                </Grid>}
                                        </Paper>
                                    </Slide>}
                                {this.state.step_printed === 0 && <Slide in={this.state.step < 1} direction={'up'}
                                    onExited={() => this.deletePaper(1)}>
                                    <Paper
                                        style={{
                                            padding: 10,
                                            zIndex: 3,
                                            borderRadius: '10px',
                                            borderTopLeftRadius: 0,
                                            width: '100%',
                                            border: '1px solid rgb(0,44,66)',
                                            position: "absolute",
                                            top: 30,
                                            left: -20

                                        }}
                                        elevation={10}>
                                        <Grid item xs style={{ position: "absolute", top: -32, left: -1 }}>
                                            <MyCalendar input_date={this.state.input_date}
                                                calendrierHandleChange={this.calendrierHandleChange}
                                                shouldDisableDate={this.shouldDisableDate}
                                                onMonthChange={this.onMonthChange}
                                                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                                    const isSelected = isInCurrentMonth && this.state.planning.findIndex(p => p.produit_id === this.state.input_produit_id && day.isSame(moment(p.date)) && !(p.fermeture > 0 || p.complet > 0)) !== -1;
                                                    if (this.state.produits && this.state.produits.length > 1)
                                                        return <Badge invisible={!isSelected} variant="dot"
                                                            color="primary">{dayComponent}</Badge>;
                                                    return dayComponent;
                                                }} />
                                        </Grid>
                                        <Tooltip title={"Réservation"} placement={"right"} style={{ top: 33 }}>
                                            <Button style={{
                                                position: 'absolute',
                                                right: -41,
                                                top: 33,
                                                minWidth: 15,
                                                minHeight: 15
                                            }} size={"small"} variant={this.state.step === 0 && 'contained' || 'outlined'}
                                                color={this.state.step === 0 && "secondary" || 'primary'}>
                                                <i className={`icon-calendar`} />
                                            </Button>
                                        </Tooltip>
                                            {(this.state.jour || []).length > 0 && !this.allProduitsMenusareEmpty(((this.state.jour || []) || [])) && !this.state.goToLogin &&
                                                <Hidden mdUp>
                                                    <Tooltip title={"Menu"} placement={"left"} style={{ top: 33 }}>
                                                    <Button style={{
                                                        position: 'absolute',
                                                            right: -71,
                                                            top: 70,
                                                            fontSize: 13,
                                                        minWidth: 15,
                                                        minHeight: 15
                                                    }} onClick={() => {
                                                        this.setState({
                                                            showMenuMobile: true
                                                        })
                                                    }} size={"small"} variant={this.state.step === 0 && 'contained' || 'outlined'}
                                                        color={this.state.step === 0 && "secondary" || 'primary'}>
                                                            <i className={`icon-reservation_couverts`} />Menu
                                                        </Button>
                                                </Tooltip>
                                                </Hidden>}
                                        <Grid container direction={'row'}>
                                            <Grid container direction={'row'} alignItems={'center'}>
                                                {this.state.produits.length > 1 &&
                                                    <Grid item xs>
                                                        <Grid container direction='row' alignItems={'center'}
                                                            className={'sectionBodyStyle'}>
                                                            <i className='icon-time ico-blue' />
                                                            <i className='date_cal'>Lieu de réservation</i>
                                                        </Grid>
                                                        <BoxProduits produits={this.state.produits}
                                                            produits_jour={(this.state.jour || []).filter(j => !(j.fermeture > 0 || j.complet > 0)).map(j => j.produit_id)}
                                                            produitHandleChange={this.produitHandleChange}
                                                            input_produit_id={this.state.input_produit_id} />
                                                    </Grid>}
                                            </Grid>
                                                {this.state.defaultLayout && this.props.width !== 'xs' ? <LayoutDefault
                                                jour={this.state.jour}
                                                input_service_id={this.state.input_service_id}
                                                input_produit_id={this.state.input_produit_id}
                                                input_heure={this.state.input_heure}
                                                comboBoxHandleChange={this.comboBoxHandleChange}
                                                comboHoraire={this.state.comboHoraire}
                                                typeHandleChange={this.typeHandleChange} />
                                                : <LayoutSimple
                                                    jour={this.state.jour}
                                                    input_service_id={this.state.input_service_id}
                                                    input_produit_id={this.state.input_produit_id}
                                                    input_heure={this.state.input_heure}
                                                    comboHoraire={this.state.comboHoraire}
                                                    servHandleChange={this.servHandleChange}
                                                    typeHandleChange={this.typeHandleChange}
                                                    comboBoxHandleChange={this.comboBoxHandleChange} />}
                                        </Grid>
                                        {this.state.couvert_max > 0 &&
                                            <Grid item className={'sectionStyle'}>
                                                <Grid container direction='column' id={'couvdiv'}>
                                                    <Grid container direction='row' alignItems={'center'}
                                                        className={'sectionBodyStyle'}>
                                                        <i className='icon-reservation_couverts ico-blue' />
                                                        <i className='date_cal'>{i18n.t('Couverts')}</i>
                                                    </Grid>
                                                    <CouvertTab max={this.state.couvert_max || 0}
                                                        value={this.state.input_quantite}
                                                            defaultLayout={this.state.defaultLayout && this.props.width !== 'xs'}
                                                        couverts={[]}
                                                        onChange={this.couvertHandleChange} />
                                                </Grid>
                                            </Grid>}
                                        <Grid item className={'sectionStyle'}>
                                            <Grid container direction='row' alignItems={'center'}
                                                className={'sectionBodyStyle'}>
                                                <i className='icon-stars ico-blue' />
                                                <Grid item xs>
                                                    <i className='date_cal'>{i18n.t('Informations complémentaires')}</i>
                                                </Grid>
                                            </Grid>
                                            <InfoSupp input_commentaire={this.state.input_commentaire}
                                                infoComplementairesHandleChange={this.infoComplementairesHandleChange} />
                                        </Grid>
                                        <Grid container direction={'row'} alignItems={'center'} justify={'flex-end'}
                                            className={'nextBtn'}>
                                            <Button className={'sv_button'} variant="contained" color="secondary"
                                                onClick={this.validateState}
                                                disabled={this.state.input_quantite === 0 || this.state.input_heure === ""}>{i18n.t('Suivant')}</Button>
                                        </Grid>
                                        {this.state.step === 0 &&
                                            <Grid container justify={"flex-end"} alignContent={"center"} alignItems={"center"}
                                                style={{ position: 'absolute', right: 10, bottom: -20, fontSize: 12 }}>
                                                <a href={'https://euresto.com'}
                                                    target={'_blank'}
                                                    className={'fa fa-bolt'}
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: '#FDFD00',
                                                        textShadow: '1px 1px 2px black'
                                                    }} />
                                        &nbsp;<span>by Euresto</span>
                                            </Grid>}
                                    </Paper>
                                </Slide>}
                            </Grid>
                        </Grid> ||
                            !this.state.showMenuMobile && <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <Paper style={{
                                padding: '10px',
                                margin: this.props.width === 'xs' ? 0 : '0px 40px',
                                marginTop: '9px',
                                borderRadius: '10px',
                                border: '1px solid rgb(0,44,66)',
                                textAlign: 'center'
                            }} elevation={10}>
                                Aucune réservation n'est disponible pour le moment
                        </Paper>
                        </Grid>
                    }
                        {this.state.step > 0 &&
                        <Hidden smDown>
                                <Grid item md={4} lg={3} xl={3} style={{
                                    margin: this.props.width === 'xs' ? 0 : '0px 40px',
                            }}>
                                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-evenly"}>
                                    <Box display={"flex"} width={{ xs: 1 }} flexDirection={"row"} justifyContent={"center"}
                                        alignItems={"center"}>
                                        <Paper elevation={4}
                                            style={{ width: '100%', padding: 10, border: "1px solid rgb(0,44,66)" }}>
                                            <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"}>
                                                <Box display={"flex"} flexDirection={"column"}
                                                    justifyContent={"flex-start"} width={"100%"}>
                                                    <Box display={"flex"} flexDirection={"row"} alignItems="center"
                                                        justifyContent={"center"} width={"100%"}>
                                                        <h2>Récapitulatif de la réservation</h2>
                                                    </Box>
                                                    <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}
                                                        alignItems="center" justifyContent={"center"}>
                                                        <ReservationLine icName={'icon-position'}
                                                            produitInfo={this.new_getProduitName(this.state.input_produit_id)} />
                                                        <ReservationLine icName={'icon-calendar'}
                                                            produitInfo={moment(this.state.input_date + ' ' + this.state.input_heure, 'YYYY-MM-DD HH:mm').format('dddd DD MMM YYYY à HH:mm')} />
                                                        <ReservationLine icName={'icon-reservation_couverts'}
                                                            produitInfo={this.state.input_quantite} />
                                                        {this.state.input_commentaire && <ReservationLine icName={'icon-stars '}
                                                            produitInfo={this.state.input_commentaire} />}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {this.state.input_menuResa ?
                                                <div>
                                                    <Divider variant="middle" style={{ marginTop: 15, marginBottom: 15 }} />
                                                    <Box display={"flex"} width={{ xs: 1 }} flexDirection={"column"}
                                                        justifyContent={"flex-start"}>
                                                        <h2>Menu</h2>
                                                        <Box display="flex" flexDirection={"row"} alignItems="flex-start"
                                                            justifyContent={"center"} flexWrap={"nowrap"}>
                                                            <Box>
                                                                <div className={'dangerously_styled'}
                                                                    style={{ textAlign: 'center' }}
                                                                    dangerouslySetInnerHTML={{ __html: this.state.input_menuResa }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </div>
                                                : null}
                                        </Paper>
                                    </Box>
                                </Box>
                            </Grid>
                        </Hidden>}
                    {(this.state.jour || []).length > 0 && !this.allProduitsMenusareEmpty(((this.state.jour || []) || [])) && !this.state.goToLogin &&
                        <Hidden smDown>
                                <Grid item md={4} lg={3} xl={3} style={{
                                    margin: this.props.width === 'xs' ? 0 : '0px 40px',
                                }}>
                                    <Paper square={true}
                                        style={{
                                            zIndex: 0,
                                            backgroundColor: "white",
                                            border: '1px rgb(0,44,66) solid',
                                            borderRadius: 5
                                        }}>
                                        <Paper square={true} style={{
                                            zIndex: 1,
                                            position: "relative",
                                            border: '1px rgb(0,44,66) solid',
                                            top: 4,
                                            left: -4,
                                            borderRadius: 5,
                                            padding: 15
                                        }}>
                                            <Menu jour={(this.state.jour || [])}
                                                produitMenu={this.state.produitMenu}
                                                menuListHandleChange={this.menuListHandleChange}
                                                input_service_id={this.state.input_service_id}
                                                menuArrowHandleClick={this.menuArrowHandleClick} />
                                        </Paper>
                                    </Paper>
                                </Grid>
                            </Hidden>}
                        {
                            this.state.showMenuMobile
                            && (this.state.jour || []).length > 0 && !this.allProduitsMenusareEmpty(((this.state.jour || []) || [])) && !this.state.goToLogin &&
                            <Grid item md={4} lg={3} xl={3} style={{
                                margin: this.props.width === 'xs' ? 0 : '0px 40px',
                            }}>
                                <Paper square={true}
                                    style={{
                                        zIndex: 0,
                                        backgroundColor: "white",
                                        border: '1px rgb(0,44,66) solid',
                                        borderRadius: 5
                                    }}>
                                    <Paper square={true} style={{
                                        zIndex: 1,
                                        position: "relative",
                                        border: '1px rgb(0,44,66) solid',
                                        top: 4,
                                        left: -4,
                                        borderRadius: 5,
                                        padding: 15
                                    }}>
                                        <Menu jour={(this.state.jour || [])}
                                            produitMenu={this.state.produitMenu}
                                            menuListHandleChange={this.menuListHandleChange}
                                            input_service_id={this.state.input_service_id}
                                            menuArrowHandleClick={this.menuArrowHandleClick} />

                                        <Tooltip title={"Menu"} placement={"left"} style={{ top: 33 }}>
                                            <Button style={{
                                                position: 'absolute',
                                                right: -41,
                                                top: 20,
                                                minWidth: 15,
                                                minHeight: 15
                                            }} onClick={() => {
                                                this.setState({
                                                    showMenuMobile: false
                                                })
                                            }} size={"small"} variant={this.state.step === 0 && 'contained' || 'outlined'}
                                                color={this.state.step === 0 && "secondary" || 'primary'}>
                                                <i className={`icon-reservation_couverts`} />
                                            </Button>
                                        </Tooltip>
                                    </Paper>

                                </Paper>
                            </Grid>
                        }
                </Grid>
            </Grid>

        );
    }
}


export default withWidth()(App);
