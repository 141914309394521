import { Button } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { URLbase } from '../utils/config.js';
import i18n from "../utils/i18n";


export default props => {
    /*
    if(props.logInForm.showPassword){
        myRef.autoFocus()
    }*/

    return <Grid item>
    <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} className='labelMarginBottom'>
        <i className={'icon-mail'}/>
        <label>{i18n.t('Adresse email')}</label>
    </Grid>
    <Grid container direction={'column'} >
        <TextField
            id='email-text'
            value={props.logInForm.email}
            onChange={props.handleEmailChange}
            onKeyPress={e => (e.key === 'Enter') && props.checkLogIn()}
            placeholder={'votremail@gmail.com'}
            type={'text'}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <Button size={'small'} variant="contained" color="secondary" onClick={props.checkLogIn}>
                            <i className={'icon-arrow'}/>
                        </Button>

                    </InputAdornment>)
            }}
        />
        <Collapse in={props.logInForm.last_email_valid === false}>
            <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}>
                <Grid item>
                    <div className={'error_input'}>{i18n.t('Adresse email invalide')}</div>
                </Grid>
            </Grid>
        </Collapse>
    </Grid>
        {props.logInForm.showPassword && <Collapse in={props.logInForm.showPassword}>
        <Grid container direction={'column'}  id={"focus-pw"}>
            <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}
                  className='labelMarginBottom'>
                <i className={'icon-password'}/>
                <label>{i18n.t('Mot de passe')}</label>
            </Grid>
            <TextField
                id='email-password'
                value={props.logInForm.password}
                onChange={props.handlePasswordChange}
                    onKeyPress={e => (e.key === 'Enter') && props.checkLogIn()}
                type={'password'}
                autoFocus={true}
                InputProps={{
                    disableUnderline: true,
                }}/>
            <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
                    <Button className={'sv_button'} variant="contained" color="secondary" onClick={props.checkLogIn}>Connexion</Button>
                </Grid>
            <Collapse in={props.logInForm.last_password_valid === false}>
                    <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                        <div className={'error_input'}>{i18n.t('Les identifiants sont incorrects')}</div>
                </Grid>
            </Collapse>
            </Grid>
            <Grid style={{ paddingTop: 5 }} container item direction={'row'} justify={'center'} alignItems={'center'}>
                <label onClick={props.toggleIframe}
                    style={{ cursor: 'pointer' }}>{i18n.t('Mot de passe oublié ?')}</label>
            </Grid>
            <Grid style={{ paddingTop: 5 }} container item direction={'row'} justify={'center'} alignItems={'center'}  >
                <div style={{ height: 1, width: '50%', backgroundColor: 'grey' }} />
            </Grid>
            <Grid style={{ paddingTop: 5 }} container item direction={'row'} justify={'center'} alignItems={'center'}>
                <label onClick={props.continueAsGuest}
                    style={{ cursor: 'pointer', fontStyle: 'italic' }}>{i18n.t(`Continuer en tant qu'invité`)}</label>
            </Grid>
        <Dialog maxWidth={"xl"} open={props.logInForm.user_forgot_password} onClose={props.toggleIframe}>
            <iframe width="1000" height={"600"} title={'forgot-pw-frame'} src={URLbase.replace('5000', '3000').replace('api', 'app') + '?passwordForgot=true'}/>
        </Dialog>
    </Collapse> }
</Grid>}
