import React from "react";
import { CircularProgress } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

moment.locale('fr');


/* Classe affichant le DatePicker */
export default props => <MuiPickersUtilsProvider utils={MomentUtils}>
    <div className="picker">
        <DatePicker
            autoOk
            id="my-calendar-unique"
            className={"calendar "}
            variant="inline"
            disableToolbar
            disablePast
            renderDay={props.renderDay}
            style={{ color: 'white' }}
            InputProps={{
                fullWidth: true,
                disableUnderline: true,
                startAdornment: <i style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }}
                    className={'icon-calendar ico-white'} />,
                style: {
                    padding: 0,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    backgroundColor: 'rgb(0,44,66)',
                    width: 150,
                    color: 'white'
                }
            }}
            maxDate={moment().add(1, 'year')}
            onMonthChange={props.onMonthChange}
            value={props.input_date}
            onChange={props.calendrierHandleChange}
            labelFunc={(date) => date.format('ddd DD MMM')} // formate la date du DatePicker dans le bon format
            shouldDisableDate={props.shouldDisableDate} //Désactive les case ou les dates ne sont pas disponibles
            leftArrowIcon={<i className={'fa fa-caret-left'} style={{
                color: 'white',
                backgroundColor: 'rgb(0,44,66)',
                borderRadius: 5,
                padding: '2.5px 10px 2.5px 7.5px',
            }} />}
            rightArrowIcon={<i className={'fa fa-caret-right'} style={{
                color: 'white',
                backgroundColor: 'rgb(0,44,66)',
                borderRadius: 5,
                padding: '2.5px 7.5px 2.5px 10px'
            }} />}
        />
        {props.loadingDates && <CircularProgress style={{ paddingLeft: '10px' }} />}
    </div>
</MuiPickersUtilsProvider>
