import { Button, Grid, MuiThemeProvider } from '@material-ui/core';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import React, { Component } from 'react';
import EmailForm from '../Composants/EmailForm.js';
import SignInForm from '../Composants/SignInForm';
import '../styles/css/Log.css';
import '../styles/css/philippe.css';
import i18n from "../utils/i18n";
import ConfirmationResa from "./ConfirmationResa";

const modalTheme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(0,44,66)'
        },
        secondary: {
            main: 'rgb(0,159,140)'
        },
        error: {
            main: 'rgb(0,159,140)'
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiPaper: {
            root: {
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingRight: '20px',
                paddingLeft: '20px',
            }
        },
        MuiInputBase: {
            root: {
                border: '1px solid rgb(0,44,66)',
                borderRadius: 5,
                marginBottom: '5px',
            },
        },
        MuiButton: {
            root: {
                border: '1px solid rgb(0,44,66)',
                fontWeight: "bold",
                color: 'white',
                marginBottom: 0,
                minWidth: 30,
            },
        },
        MuiIconButton: {
            label: {
                marginLeft: '0px',
            }
        },
        MuiInput: {
            root: {
                padding: '6px',
            }
        },
        MuiCheckbox: {
            root: {
                padding: 0,
            }
        },
        MuiDialog: {
            paperScrollPaper: {
                padding: '10px'
            },
            paperWidthXl: {
                padding: '0px'
            }
        },
        MuiSelect: {
            selectMenu: {
                paddingRight: 30,
            },
            icon: {
                right: 0,
            }
        },
        MuiMenu: {
            paper: {
                marginTop: '40px',
                padding: 0
            }
        },
        MuiList: {
            padding: {
                padding: '0'
            }
        }
    },
});

class LogPaper extends Component {

    render() {
        return (
            <MuiThemeProvider theme={modalTheme}>
                {!this.props.resaConfirmed &&
                <Grid container direction={"row"} alignItems={'center'} justify={'center'} className={'bodys'}>
                    {!this.props.logInForm.userLoggedIn &&
                    <Grid container direction={"row"} alignItems={"flex-start"} justify={"center"}>
                                <Grid item xs={1} style={{ position: 'absolute', top: 10, left: 10 }}>
                            <Button variant="contained" color={'secondary'} size="small" onClick={this.props.returnToReservation} style={{
                                color: 'white',
                                fontSize: 20,
                                cursor: "pointer",
                                marginBottom: 5
                            }}><i className={'fa fa-caret-left'}/></Button>
                        </Grid>
                        <Grid item xs>
                            <h2>{i18n.t('Renseignez vos coordonnées')}</h2>
                            <EmailForm logInForm={this.props.logInForm}
                                       handlePasswordChange={this.props.handlePasswordChange}
                                       handleEmailChange={this.props.handleEmailChange}
                                        togglePassword={this.props.togglePassword}
                                        continueAsGuest={this.props.continueAsGuest}
                                        checkLogIn={this.props.checkLogIn}
                                        toggleIframe={this.props.toggleIframe}
                                       keyPressLogIn={this.props.keyPressLogIn}/>
                        </Grid>
                    </Grid>}
                    {this.props.logInForm.userLoggedIn && !this.props.userKnown && !this.props.logInForm.showPassword &&
                            <Grid container direction={'row'}>
                                <Grid item xs={1} style={{ position: 'absolute', top: 10, left: 10 }}>
                            <Button variant="contained" color={'secondary'} size="small" onClick={this.props.returnToLogIn} style={{
                                color: 'white',
                                fontSize: 20,
                                cursor: "pointer",
                                marginBottom: 5
                            }}><i className={'fa fa-caret-left'}/></Button>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                                <h2>{i18n.t('Renseignez vos coordonnées')}</h2>
                                <SignInForm userForm={this.props.userForm}
                                            verificationPortable={this.props.verificationPortable}
                                            onClickVerifPhone={this.props.onClickVerifPhone}
                                            asGuest={this.props.asGuest}
                                            checkEquality={this.props.checkEquality}
                                            code_drop_down={this.props.code_drop_down}
                                            handleFormChange={this.props.handleFormChange}
                                            nextOnclick={this.props.nextOnclick}
                                            onExitInputField={this.props.onExitInputField}
                                            handlePortableCodeChange={this.props.handlePortableCodeChange}
                                            handlePortableChange={this.props.handlePortableChange}
                                            userForm_enableVerif={this.props.userForm_enableVerif}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                    {this.props.logInForm.error && this.props.logInForm.error.length &&
                    <Grid item xs style={{color: 'red'}}>
                        {this.props.logInForm.error}
                    </Grid>
                    }
                </Grid>}
                {this.props.resaConfirmed && <ConfirmationResa closeResa={this.props.closeResa}
                    message_fin_resa={this.props.message_fin_resa || 'Votre demande de réservation a bien été prise en compte.'} />}
            </MuiThemeProvider>
        );
    }
}

export default LogPaper;
