import Grid from "@material-ui/core/Grid";
import React, { useCallback, useEffect } from "react";
import ConfirmationResa from "./ConfirmationResa";

export default React.memo(props => {

    const receiveMessage = useCallback((event) => {
        if (event && event.data) {
            if (event.data.paiement) {
                return props.paymentSuccess()
            }
        }
    }, [])

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false)
    }, [])

    return(
        <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
            {props.payURL === undefined && <ConfirmationResa closeResa={props.closeResa} message_fin_resa={props.message_fin_resa || 'Votre réservation a bien été prise en compte.'}/>}
            {props.payURL && !props.resaConfirmed ? <iframe id={'iframe-payment'} style={{width: '100%', height: 800}} src={props.payURL} sandbox frameBorder={"0"} height={'auto'}  scrolling={'no'} title={'payframe'} />:null}
            {props.resaConfirmed && <ConfirmationResa closeResa={props.closeResa} message_fin_resa={props.message_fin_resa || 'Votre réservation a bien été prise en compte.'}/>}
        </Grid>
    );
});
