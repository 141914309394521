import React from 'react';
import Grid from '@material-ui/core/Grid/index';


class ReservationLine extends React.Component {
    render() {
        return (
            <Grid container direction={'row'} alignItems={'center'} justify={'flex-start'} style={{ marginBottom: 5 }}>
                <i className={`${this.props.icName + ' ico-blue'}`} style={{ maxHeight: '20px', marginRight: 10 }} alt={'bla1'} />
                {/*  <i className={this.props.icName}/>*/}
                <span>{this.props.intitule}{this.props.produitInfo}</span>
            </Grid>
        );
    }
}

export default ReservationLine;